<template>
	<div class="content">
		<div v-if="contractDetail.id" style="height: 100%">
			<div class="content__hd menus">
				<div class="title">{{ contractDetail.name }}</div>
				<Menu mode="horizontal" theme="light" :active-name="activeName" @on-select="onSelectMemu">
					<MenuItem name="detail">列表</MenuItem>
					<MenuItem name="sign">信息</MenuItem>
					<MenuItem name="sign-list">签名</MenuItem>
					<MenuItem name="seal">盖章</MenuItem>
					<MenuItem name="log">日志</MenuItem>
				</Menu>
			</div>
			<div class="content__bd">
				<div v-if="activeName == 'detail'" class="wrapper" style="height:100%;">
					<Table ref="table" class="table" :columns="columns" :data="contractDetail.fileList">
						<template slot-scope="{row}" slot="action">
							<Upload v-if="row.is_attach === 0" :show-upload-list="false"
								:before-upload="(file) => { beforeUploadAttachment(file, row.temp_id) }" action="#"
								accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf" style="display: inline-block;">
								<!-- .jpg, .jpeg, .png, .gif,  -->
								<Button type="success" size="small" style="margin-left:10px">添加附件</Button>
							</Upload>
							<Button v-else @click="onDeleteAttach(row)" type="error" size="small"
								style="margin-left:10px">删除附件</Button>
							<Button v-if="row.is_attach === 0" @click="onViewTempRow(row)" type="primary" size="small"
								style="margin-left:10px">查看</Button>
							<Button v-else @click="onViewAttach(row)" type="primary" size="small"
								style="margin-left:10px">查看</Button>

						</template>
					</Table>
				</div>
				<div v-else-if="activeName == 'sign'" class="wrapper">
					<template v-if="contractDetail.isCreater">
						<div class="layout-row" style="align-items: start;">
							<div>
								<div class="sign-row">
									<span style="margin-right:10px;">分享链接：</span>
									<div class="share-link">{{ shareLink }}</div>
									<a class="copy-btn" v-clipboard:copy="shareLink" v-clipboard:success="onCopySuccess"
										v-clipboard:error="onCopyError">复制</a>
								</div>
								<div class="sign-row">
									<span style="margin-right:10px;">分享设置：</span>
									<RadioGroup v-model="contractDetail.sharePrivate" @on-change="onPrivateChange">
										<Radio :label="0">公开</Radio>
										<Radio :label="1">私密</Radio>
									</RadioGroup>
								</div>
								<div class="sign-row">
									<template v-if="contractDetail.sharePrivate == 1">
										<span style="margin-right:10px;">6位分享密码：</span>
										<!-- <div style="margin: 0 10px 0 20px;">6位分享密码：</div> -->
										<Input v-model="contractDetail.sharePasswd" placeholder="分享密码" maxlength="6"
											style="width: 160px;margin-right: 30px;"></Input>
										<Button type="primary" @click="onSavePrivate">保存</Button>
									</template>
								</div>
							</div>
							<div class="share-img-box">
								<viewer>
									<img class="share-img" v-if="shareUrl" :src="shareUrl" alt="">
								</viewer>
							</div>
						</div>
					</template>
					<div v-else class="sign-row">
						<span style="margin-right:10px;">分享用户：</span>
						<div class="share-link">{{ contractDetail.share_user }}</div>
					</div>
					<div class="sign-row">
						<Button type="default" icon="ios-cloud-download-outline" style="margin-right: 20px;"
							@click="onContractDownload">资料下载</Button>
						<Button type="default" icon="ios-cloud-upload-outline" @click="showUpload = true">上传图片</Button>
					</div>
					<div class="section__title">图片列表：</div>
					<div class="sign__panel">
						<div v-for="item in signInfo" :key="item.id" class="item">
							<div class="info">
								<Button type="default" icon="ios-cloud-download-outline"
									@click="onDownloadSignImage(item.id)">下载</Button>
								<div class="label">{{ item.signer }}</div>
							</div>
							<div class="pic__bd">
								<div v-for="pic in item.pictures" :key="pic.id" class="pic">
									<img class="img" preview="sign-img" :src="pic.url" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="activeName == 'sign-list'" class="wrapper sign-view" style="height:100%;">
					<Collapse v-model="collapseValue">
						<Panel v-for="(signListGroup, index) in contractDetail.signList" :key="index"
							:name="index.toString()">
							{{ signListGroup.unit_name }}
							<div slot="content">
								<Table ref="table" class="table" :columns="signColumns" :data="signListGroup.sign_list"
									:show-header="false">
								</Table>
							</div>
						</Panel>
					</Collapse>
				</div>
				<div v-if="activeName == 'seal'" class="wrapper seal-view" style="height:100%;">
					<Collapse v-model="collapseValue">
						<Panel v-for="(sealListGroup, index) in contractDetail.sealList" :key="index"
							:name="index.toString()">
							{{ sealListGroup.unit_name }}
							<div slot="content">
								<Table ref="table" class="table" :columns="sealColumns" :data="sealListGroup.seal_list"
									:show-header="false">
								</Table>
							</div>
						</Panel>
					</Collapse>
				</div>
				<div v-else-if="activeName == 'log'" class="wrapper">
					<Steps direction="vertical">
						<Step v-for="item in logList" :key="item.id" status="process" :title="item.create_date">
							<div slot="content">
								<span>{{ item.real_name }}</span>
								<!-- <span>{{item.username}}</span> -->
								<!-- <span v-if="item.real_name" style="margin-left:4px; color:#1e90ff;">[{{item.real_name}}]</span> -->
								<span style="margin-left:8px">-</span>
								<span style="margin-left:8px">{{ item.description }}</span>
							</div>
						</Step>
					</Steps>
				</div>
			</div>
		</div>
		<div v-else class="content__err">
			<a v-if="!hasLogin" @click="onLogin">请先登录</a>
			<a v-else-if="isPrivate" @click="onJoin">私密资料，请输入密码打开</a>
			<span v-else-if="errorMsg">{{ errorMsg }}</span>
		</div>
		<UploadSign v-model="showUpload" :info="{ id: contractID }" @on-success="onUploadSuccess" />
		<JoinContract v-model="showJoin" :info="{ id: contractID }" @on-success="onJoinSuccess" />
		<DocxViewer v-model="tempInfo" />
	</div>
</template>

<script>
import UploadSign from '@/components/UploadSignImage.vue'
import JoinContract from '@/components/JoinContract.vue'
import DocxViewer from '@/components/DocxViewer'
import {
	mapMutations,
	mapGetters
} from 'vuex'
import {
	uploadAttachment,
	deleteAttachment,
	getContractDetail,
	shareQRCode,
	downloadSignZip,
	downloadContractZip,
	getContractSignInfo,
	setPrivateSetting,
	getContractLog
} from '@/api/contract.js'

export default {
	name: 'Contract',
	components: {
		UploadSign,
		JoinContract,
		DocxViewer
	},
	data() {
		return {
			activeName: 'detail', // 'detail' 'sign' 'log'
			contractDetail: {
				id: null,
				name: null,
				isCreater: false,
				sharePasswd: null,
				sharePrivate: 0, // 0-公开 1-私密
				fileList: null,
				signList: null,
				sealList: null,
				share_user: null
			},
			errorMsg: null,

			tempInfo: null,
			signInfo: [],

			logList: null,

			showUpload: false,
			isPrivate: false,
			showJoin: false,

			collapseValue: '0',

			shareUrl: null
		}
	},
	computed: {
		...mapGetters(['hasLogin']),
		contractID() {
			return this.$route.params.id
		},
		shareLink() {
			return window.location.protocol + '//' + window.location.host + '/contract/' + this.contractID
		},
		columns() {
			return [{
				key: 'name',
				title: '名称',
				minWidth: 300,
				render(h, params) {
					return h('div', params.row.is_attach === 0 ? parseFloat(params.row.order) + '、' + params
						.row.name : parseFloat(params.row.order) + '、' + params.row.attach_name)
				}
			}, {
				slot: 'action',
				title: '操作',
				width: 200,
				align: 'center'
			}]
		},
		signColumns() {
			return [{
				key: 'signer',
				title: '签名方',
				minWidth: 300,
				align: 'left'
			}, {
				slot: 'temps',
				title: '文档',
				align: 'right',
				render(h, params) {
					const ret = '(' + (params.row.temp_ids.split(',').map(p => parseFloat(p)).join(',')) + ')'
					return h('div', {
						style: {}
					}, ret)
				}
			}, {
				slot: 'status',
				title: '状态',
				width: 80,
				align: 'right',
				render(h, params) {
					const ret = (params.row.is_sign === 1 ? '已签名' : '未签名')
					return h('div', {
						style: {
							color: params.row.is_sign === 1 ? 'green' : 'red'
						}
					}, ret)
				}
			}, {
				slot: 'status',
				title: '签名',
				width: 100,
				align: 'right',
				render(h, params) {
					const ret = params.row.creater
					return h('div', {
						style: {}
					}, ret)
				}
			}]
		},
		sealColumns() {
			return [{
				key: 'seal_name',
				title: '盖章名称',
				minWidth: 300,
				align: 'left'
			}, {
				slot: 'temps',
				title: '文档',
				align: 'right',
				render(h, params) {
					const ret = '(' + (params.row.temp_ids.split(',').map(p => parseFloat(p)).join(',')) + ')'
					return h('div', {
						style: {}
					}, ret)
				}
			}, {
				slot: 'status',
				title: '状态',
				width: 80,
				align: 'right',
				render(h, params) {
					const ret = (params.row.is_seal === 1 ? '已盖章' : '未盖章')
					return h('div', {
						style: {
							color: params.row.is_seal === 1 ? 'green' : 'red'
						}
					}, ret)
				}
			}, {
				slot: 'status',
				title: '盖章方',
				width: 100,
				align: 'right',
				render(h, params) {
					const ret = params.row.creater
					return h('div', {
						style: {}
					}, ret)
				}
			}]
		}
	},
	watch: {
		hasLogin(newVal) {
			if (newVal) {
				this.initData()
			} else {
				Object.assign(this.$data, this.$options.data.call(this))
				// this.$router.replace({ name: this.$config.homeName })
				// this.$router.push({ name: this.$config.homeName })
			}
		}
	},
	mounted() {
		this.initData()
	},
	methods: {
		...mapMutations(['showLoginDialog']),
		async initData() {
			try {
				const params = {
					id: this.contractID
				}
				const res = await getContractDetail(params, [401, 402, 1441])
				if (res.code == 200) {
					this.contractDetail.id = res.content.id
					this.contractDetail.contract_no = res.content.contract_no
					this.contractDetail.name = res.content.name
					this.contractDetail.isCreater = res.content.is_creater == 'y'
					this.contractDetail.fileList = res.content.file_list
					this.contractDetail.signList = res.content.sign_list
					this.contractDetail.sealList = res.content.seal_list
					if (this.contractDetail.isCreater) {
						this.contractDetail.sharePrivate = parseInt(res.content.is_private)
						this.contractDetail.sharePasswd = res.content.share_passwd
					}
					this.contractDetail.share_user = res.content.share_user

					// 获取分享二维码
					const res2 = await shareQRCode({
						contract_id: this.contractDetail.id
					})
					this.shareUrl = res2.content.url1
				} else if (res.code == 1441) {
					this.isPrivate = true
					this.showJoin = true
				} else {
					this.showLoginDialog()
				}
			} catch (err) {
				this.errorMsg = err.message
				this.$Message.error(err.message)
			}
		},
		onViewTempRow(row) {
			var url = row.url + "?t=" + Date.now();
			window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
		},
		async getSignData() {
			try {
				const params = {
					id: this.contractID
				}
				const res = await getContractSignInfo(params)
				this.signInfo = res.content.sign_info

				// 获取合同分享二维码

				this.$previewRefresh()
			} catch (err) {
				this.$Message.error(err.message)
			}
		},
		async onSelectMemu(name) {
			this.activeName = name
			if (name == 'detail') {
				this.initData()
			} else if (name == 'sign') {
				this.getSignData()
			} else if (name == 'log') {
				try {
					const params = {
						id: this.contractID
					}
					const res = await getContractLog(params)
					this.logList = res.content.rows
				} catch (err) {
					this.$Message.error(err.message)
				}
			}
		},
		async savePrivate(isPrivate) {
			try {
				const params = {
					id: this.contractID
				}
				if (isPrivate == null) {
					params.private_flag = this.contractDetail.sharePrivate == 1 ? 'y' : 'n'
				} else {
					params.private_flag = isPrivate == 1 ? 'y' : 'n'
				}
				params.password = this.contractDetail.sharePasswd
				await setPrivateSetting(params)
				if (isPrivate == null) {
					this.$Message.success('保存成功！')
				}
			} catch (err) {
				this.$Message.error(err.message)
			}
		},
		onPrivateChange(val) {
			this.savePrivate(val)
		},
		onSavePrivate() {
			this.savePrivate()
		},
		onCopySuccess() {
			this.$Message.success('复制成功！')
		},
		onCopyError() {
			this.$Message.error('复制失败！')
		},
		async onContractDownload() {
			try {
				this.$Spin.show()
				const res = await downloadContractZip({
					id: this.contractID
				})

				let filename = res.headers['content-disposition']
				filename = decodeURIComponent(filename.split('filename=')[1])
				filename = filename.replace(/"/g, '')

				const blob = res.data
				// 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
				// IE10以上支持blob但是依然不支持download
				const downloadElement = document.createElement('a')
				if ('download' in downloadElement) {
					const href = window.URL.createObjectURL(blob) // 创建下载的链接
					downloadElement.href = href
					downloadElement.download = filename
					document.body.appendChild(downloadElement)
					downloadElement.click() // 点击下载
					window.URL.revokeObjectURL(href) // 释放掉blob对象
					document.body.removeChild(downloadElement) // 下载完成移除元素
				} else {
					navigator.msSaveBlob(blob, filename)
				}
				this.$Spin.hide()
			} catch (err) {
				this.$Spin.hide()
				this.$Message.error(err.message)
			}
		},
		async onDownloadSignImage(signID) {
			try {
				this.$Spin.show()
				const params = {
					contract_id: this.contractID,
					sign_id: signID
				}
				const res = await downloadSignZip(params)

				let filename = res.headers['content-disposition']
				filename = decodeURIComponent(filename.split('filename=')[1])
				filename = filename.replace(/"/g, '')

				const blob = res.data
				// 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
				// IE10以上支持blob但是依然不支持download
				const downloadElement = document.createElement('a')
				if ('download' in downloadElement) {
					const href = window.URL.createObjectURL(blob) // 创建下载的链接
					downloadElement.href = href
					downloadElement.download = filename
					document.body.appendChild(downloadElement)
					downloadElement.click() // 点击下载
					window.URL.revokeObjectURL(href) // 释放掉blob对象
					document.body.removeChild(downloadElement) // 下载完成移除元素
				} else {
					navigator.msSaveBlob(blob, filename)
				}
				this.$Spin.hide()
			} catch (err) {
				this.$Spin.hide()
				this.$Message.error(err.message)
			}
		},
		onUploadSuccess() {
			this.getSignData()
		},
		onJoinSuccess() {
			this.initData()
		},
		onJoin() {
			this.showJoin = true
		},
		onLogin() {
			this.showLoginDialog()
		},
		async beforeUploadAttachment(file, temp_id) {
			const fileDes = []
			fileDes.push({
				name: 'file',
				file
			})
			await uploadAttachment(fileDes, {
				temp_id,
				contract_no: this.contractDetail.contract_no
			}).then(async response => {
				await this.initData()
			})
			return false
		},
		onViewAttach(row) {
			// window.open('https://view.officeapps.live.com/op/view.aspx?src=' + row.url)
			row.name = row.attach_name
			this.tempInfo = {
				type: 'contract',
				detail: row
			}
		},
		onDeleteAttach(row) {
			// console.log(row)
			this.$Modal.confirm({
				title: '提示',
				content: '确定删除附件吗？',
				onOk: async () => {
					await deleteAttachment({
						contract_file_id: row.id
					}).then(async response => {
						await this.initData()
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	position: relative;
	width: 100%;
	height: 100%;
	background: white;

	.content__hd {
		position: relative;
		width: auto;
		min-width: 800px;
		max-width: 980px;
		margin: 0 auto;
		padding-bottom: 10px;
		padding-right: 18px;
		background-color: white;
		z-index: 10;

		::v-deep .ivu-menu-horizontal {
			display: flex;
			justify-content: center;
			height: 50px;
			line-height: 50px;
		}
	}

	.content__bd {
		height: ~"calc(100% - 70px)";
		min-width: 800px;
		overflow: auto;

		.wrapper {
			padding: 0 20px 40px;
			min-width: 800px;
			max-width: 980px;
			margin: 0 auto;
		}

		.share-img-box {
			padding-left: 40px;

			.share-img {
				width: 150px;
				height: 150px;
			}
		}
	}

	.content__err {
		padding: 40px 0;
		width: fit-content;
		margin: 0 auto;
	}
}

.menus {
	position: relative;

	.title {
		position: absolute;
		top: 5px;
		left: 0;
		z-index: 901;
		width: 300px;
		// border: 1px solid #e0e0e0;

		padding-top: 5px;
		padding-left: 20px;
		font-size: 24px;
		font-weight: 700;
		// text-align: center;

		white-space: nowrap;
		/*一行显示*/
		overflow: hidden;
		/*超出部分隐藏*/
		text-overflow: ellipsis;
		/*用...代替超出部分*/
	}
}

.table {
	height: 100%;

	::v-deep .ivu-table-body {
		height: ~"calc(100% - 39px)";
		overflow: hidden auto;
	}
}

.sign-row {
	display: flex;
	align-items: center;
	height: 40px;
	margin: 5px 0;

	.share-link {
		word-wrap: break-word;
		margin-right: 20px;
		font-size: 14px;
	}

	.copy-btn {
		font-size: 14px;
	}
}

.section__title {
	margin-top: 20px;
}

.sign__panel {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	margin: 5px 20px 20px 0;
	border: solid 1px #eee;
	padding: 10px;
	min-height: 186px;

	.item {
		margin-top: 5px;
		width: 100%;

		.info {
			display: flex;
			align-items: center;
			margin-left: 10px;

			.label {
				margin-left: 20px;
			}
		}

		.pic__bd {
			display: flex;
			justify-content: flex-start;
			flex-flow: wrap;

			.pic {
				position: relative;
				width: 120px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 10px;

				.img {
					width: 120px;
					height: 120px;
					border-radius: 5px;
					background-color: #eee;
					margin-bottom: 10px;
				}
			}
		}
	}
}

.sign-view,
.seal-view {

	/deep/.ivu-collapse-content {
		padding: 0 !important;

		.ivu-collapse-content-box {
			padding: 0 !important;
		}
	}
}
</style>
