<template>
  <Modal v-model="pShown" :mask-closable="false" title="上传图片图片" :width="800">
    <Form :label-width="90">
      <FormItem label="文档图片:">
        <draggable class="sign-imgs">
          <div v-for="(item, index) in signImages" :key="index" class="item">
            <viewer :images="signImages.map(p => p.src)">
              <img :src="item.src" />
            </viewer>
            <div class="close">
              <Icon type="ios-close-circle" color="red" @click.native="onRemoveImage(index)"></Icon>
            </div>
          </div>
        </draggable>
        <Upload :show-upload-list="false" multiple :before-upload="beforeUpload" action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
          <Button icon="md-add" style="margin-bottom: 20px;">添加图片</Button>
        </Upload>
      </FormItem>
      <!-- <FormItem label="签名方:">
        <RadioGroup v-model="signType">
          <Radio :label="0">甲方</Radio>
          <Radio :label="1">乙方</Radio>
          <Radio :label="2">其他</Radio>
        </RadioGroup>
        <Input v-if="signType == 2" v-model="otherSignPerson" placeholder="签名人" style="width: 110px;"></Input>
      </FormItem> -->
    </Form>
    <div slot="footer">
      <Button type="default" :disabled="loading" @click="pShown = false">取消</Button>
      <Button type="primary" :disabled="loading" @click="onSave">保存</Button>
    </div>
  </Modal>
</template>

<script>
import draggable from 'vuedraggable'
import { signContract } from '@/api/contract.js'

export default {
  name: 'UploadSignImage',
  model: {
    prop: 'shown',
    event: 'change'
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      require: true
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      loading: false,
      pShown: false,

      signImages: [],
      signType: 2, // 0-甲方 1-乙方 2-其他
      otherSignPerson: /* null */'无'
    }
  },
  computed: {
  },
  watch: {
    pShown(newVal) {
      this.$emit('change', newVal)
      if (newVal) {
      } else {
        setTimeout(() => {
          this.loading = false
          this.signImages = []
          this.signType = 0
          this.otherSignPerson = null
        }, 300)
      }
    },
    shown(newVal) {
      this.pShown = newVal
    }
  },
  methods: {
    async onSave() {
      let signer = '甲方'
      if (this.signType == 1) {
        signer = '乙方'
      } else if (this.signType == 2) {
        signer = this.otherSignPerson
      }

      if (signer == null || signer.length == 0) {
        this.$Message.error('签名人不能为空')
        return
      }

      this.loading = true
      try {
        const imgFiles = this.signImages.map(item => item.file)

        const fileDes = {name: 'image[]', file: imgFiles}
        const params = { id: this.info.id, signer }
        await signContract(fileDes, params)
        this.$emit('on-success')
        this.pShown = false
        this.$Message.success('保存成功！')
      } catch (err) {
        this.$Message.error(err.message)
        this.loading = false
      }
    },
    beforeUpload(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        this.signImages.push({
          src: reader.result,
          file: file
        })
      }
      return false
    },
    onRemoveImage(index) {
      this.signImages.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.sign-imgs {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  max-height: 360px;
  min-height: 137px;
  overflow: auto;
  border: solid 1px #eee;
  border-radius: 5px;
  padding: 5px 0 0 10px;
  .item {
    position: relative;
    width: 110px;
    height: 110px;
    margin-right: 10px;
    margin-bottom: 20px;
    img {
      position: absolute;
      top: 10px;
      left: 0;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 0;
      right: 0;
      i {
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%);
      }
    }
    .close:hover {
      filter: opacity(0.6);
    }
  }
}
</style>
