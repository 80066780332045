<template>
  <Modal v-model="pShown" :mask-closable="false" title="私密资料" :closable="true" :width="320">
    <div style="margin-bottom: 5px;">私密资料，请输入密码打开</div>
    <Input v-model="password" type="password" placeholder="密码" @on-change="onChange"></Input>
    <div class="err-msg">{{errMsg}}</div>
    <div slot="footer">
      <Button type="primary" :disabled="loading" @click="onJoin">打 开</Button>
    </div>
  </Modal>
</template>

<script>
import { joinContract } from '@/api/contract.js'

export default {
  name: 'JoinContract',
  model: {
    prop: 'shown',
    event: 'change'
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      pShown: this.shown,

      password: null,
      errMsg: null
    }
  },
  computed: {
  },
  watch: {
    pShown(newVal) {
      this.$emit('change', newVal)
      if (newVal) {
      } else {
        setTimeout(() => {
          this.loading = false
          this.password = null
          this.errMsg = null
        }, 300)
      }
    },
    shown(newVal) {
      this.pShown = newVal
    }
  },
  methods: {
    onChange() {
      this.errMsg = null
    },
    async onJoin() {
      if (this.password == null || this.password.length == 0) {
        this.errMsg = '密码不能为空'
        return
      }

      this.loading = true
      try {
        const params = { id: this.info.id, password: this.password}
        await joinContract(params)
        this.$emit('on-success')
        this.pShown = false
      } catch (err) {
        // this.$Message.error(err.message)
        this.errMsg = err.message
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.err-msg {
  color: #ed4014;
  height: 20px;
  line-height: 20px;
}
</style>
